import React, {useEffect} from 'react'
import Cont from "./../Component/Contact/Contact"
const Contact = () => {

    useEffect(()=>{
    window.scrollTo(0,0)


// Contact Page Meta Tags
document.title = 'Vastushilp Interior Designs : Best Interior Designers in Pune | Contact Us';

// Set meta description dynamically
const contactMetaDescription = document.createElement('meta');
contactMetaDescription.name = 'description';
contactMetaDescription.content = 'Get in touch with Vastushilp Interior Designs, the best interior designers in Pune. Contact us for inquiries, consultations, and to start your journey to a beautifully designed space.';
document.head.appendChild(contactMetaDescription);

// Set meta keywords dynamically
const contactMetaKeywords = document.createElement('meta');
contactMetaKeywords.name = 'keywords';
contactMetaKeywords.content = 'Contact Vastushilp Interior Designs, Best Interior Designers in Pune, Interior Design Inquiries, Interior Design Consultations, Pune Interior Decorators, Vastu Shastra Design, Residential Interiors, Commercial Interiors, Interior Design Services Pune';
document.head.appendChild(contactMetaKeywords);

// Set canonical link dynamically
const contactCanonicalLink = document.createElement('link');
contactCanonicalLink.rel = 'canonical';
contactCanonicalLink.href = 'https://vastushilpdesigns.com/contact';
document.head.appendChild(contactCanonicalLink);

// Set Open Graph title dynamically
const contactOgTitle = document.createElement('meta');
contactOgTitle.property = 'og:title';
contactOgTitle.content = 'Vastushilp Interior Designs : Best Interior Designers in Pune | Contact';
document.head.appendChild(contactOgTitle);

// Set Open Graph description dynamically
const contactOgDescription = document.createElement('meta');
contactOgDescription.property = 'og:description';
contactOgDescription.content = 'Get in touch with Vastushilp Interior Designs, the best interior designers in Pune. Contact us for inquiries, consultations, and to start your journey to a beautifully designed space.';
document.head.appendChild(contactOgDescription);

// Set Open Graph keywords dynamically
const contactOgKeywords = document.createElement('meta');
contactOgKeywords.property = 'og:keywords';
contactOgKeywords.content = 'Contact Vastushilp Interior Designs, Best Interior Designers in Pune, Interior Design Inquiries, Interior Design Consultations, Pune Interior Decorators, Vastu Shastra Design, Residential Interiors, Commercial Interiors, Interior Design Services Pune';
document.head.appendChild(contactOgKeywords);

// Set Open Graph URL dynamically
const contactOgUrl = document.createElement('meta');
contactOgUrl.property = 'og:url';
contactOgUrl.content = 'https://vastushilpdesigns.com/contact';
document.head.appendChild(contactOgUrl);

// Set Open Graph site name dynamically
const contactOgSiteName = document.createElement('meta');
contactOgSiteName.property = 'og:site_name';
contactOgSiteName.content = 'Vastushilp Interior Designs';
document.head.appendChild(contactOgSiteName);

// Set Open Graph image dynamically
const contactOgImage = document.createElement('meta');
contactOgImage.property = 'og:image';
contactOgImage.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(contactOgImage);

// Set Open Graph secure image URL dynamically
const contactOgImageSecure = document.createElement('meta');
contactOgImageSecure.property = 'og:image:secure_url';
contactOgImageSecure.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(contactOgImageSecure);

// Set Open Graph image width dynamically
const contactOgImageWidth = document.createElement('meta');
contactOgImageWidth.property = 'og:image:width';
contactOgImageWidth.content = '300';
document.head.appendChild(contactOgImageWidth);

// Set Open Graph image height dynamically
const contactOgImageHeight = document.createElement('meta');
contactOgImageHeight.property = 'og:image:height';
contactOgImageHeight.content = '200';
document.head.appendChild(contactOgImageHeight);

// Cleanup on component unmount
return () => {
  document.title = 'Vastushilp Interior Designs : Best Interior Designers in Pune | Contact'; // Reset the title if needed
  document.head.removeChild(contactMetaDescription);
  document.head.removeChild(contactMetaKeywords);
  document.head.removeChild(contactCanonicalLink);
  document.head.removeChild(contactOgTitle);
  document.head.removeChild(contactOgDescription);
  document.head.removeChild(contactOgKeywords);
  document.head.removeChild(contactOgUrl);
  document.head.removeChild(contactOgSiteName);
  document.head.removeChild(contactOgImage);
  document.head.removeChild(contactOgImageSecure);
  document.head.removeChild(contactOgImageWidth);
  document.head.removeChild(contactOgImageHeight);
};


},[])
  return (
    <div>
      <Cont/>
    </div>
  )
}

export default Contact