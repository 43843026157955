import React, {useEffect} from 'react'
import Gall from "./../Component/Gallery/Gallery"
const Gallery = () => {

    useEffect(()=>{
    window.scrollTo(0,0)

// Gallery Page Meta Tags
document.title = 'Vastushilp Interior Designs : Best Interior Designers in Pune | Gallery';

// Set meta description dynamically
const galleryMetaDescription = document.createElement('meta');
galleryMetaDescription.name = 'description';
galleryMetaDescription.content = 'Explore the gallery of Vastushilp Interior Designs. See our portfolio of beautifully designed residential and commercial spaces in Pune, inspired by Vastu Shastra.';
document.head.appendChild(galleryMetaDescription);

// Set meta keywords dynamically
const galleryMetaKeywords = document.createElement('meta');
galleryMetaKeywords.name = 'keywords';
galleryMetaKeywords.content = 'Vastushilp Interior Designs Gallery, Interior Design Gallery, Interior Design Portfolio, Residential Interior Design Gallery, Commercial Interior Design Gallery, Best Interior Designers in Pune, Vastu Shastra Design, Pune Interior Decorators';
document.head.appendChild(galleryMetaKeywords);

// Set canonical link dynamically
const galleryCanonicalLink = document.createElement('link');
galleryCanonicalLink.rel = 'canonical';
galleryCanonicalLink.href = 'https://vastushilpdesigns.com/gallery';
document.head.appendChild(galleryCanonicalLink);

// Set Open Graph title dynamically
const galleryOgTitle = document.createElement('meta');
galleryOgTitle.property = 'og:title';
galleryOgTitle.content = 'Vastushilp Interior Designs : Best Interior Designers in Pune | Gallery';
document.head.appendChild(galleryOgTitle);

// Set Open Graph description dynamically
const galleryOgDescription = document.createElement('meta');
galleryOgDescription.property = 'og:description';
galleryOgDescription.content = 'Explore the gallery of Vastushilp Interior Designs. See our portfolio of beautifully designed residential and commercial spaces in Pune, inspired by Vastu Shastra.';
document.head.appendChild(galleryOgDescription);

// Set Open Graph keywords dynamically
const galleryOgKeywords = document.createElement('meta');
galleryOgKeywords.property = 'og:keywords';
galleryOgKeywords.content = 'Vastushilp Interior Designs Gallery, Interior Design Gallery, Interior Design Portfolio, Residential Interior Design Gallery, Commercial Interior Design Gallery, Best Interior Designers in Pune, Vastu Shastra Design, Pune Interior Decorators';
document.head.appendChild(galleryOgKeywords);

// Set Open Graph URL dynamically
const galleryOgUrl = document.createElement('meta');
galleryOgUrl.property = 'og:url';
galleryOgUrl.content = 'https://vastushilpdesigns.com/gallery';
document.head.appendChild(galleryOgUrl);

// Set Open Graph site name dynamically
const galleryOgSiteName = document.createElement('meta');
galleryOgSiteName.property = 'og:site_name';
galleryOgSiteName.content = 'Vastushilp Interior Designs';
document.head.appendChild(galleryOgSiteName);

// Set Open Graph image dynamically
const galleryOgImage = document.createElement('meta');
galleryOgImage.property = 'og:image';
galleryOgImage.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(galleryOgImage);

// Set Open Graph secure image URL dynamically
const galleryOgImageSecure = document.createElement('meta');
galleryOgImageSecure.property = 'og:image:secure_url';
galleryOgImageSecure.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(galleryOgImageSecure);

// Set Open Graph image width dynamically
const galleryOgImageWidth = document.createElement('meta');
galleryOgImageWidth.property = 'og:image:width';
galleryOgImageWidth.content = '300';
document.head.appendChild(galleryOgImageWidth);

// Set Open Graph image height dynamically
const galleryOgImageHeight = document.createElement('meta');
galleryOgImageHeight.property = 'og:image:height';
galleryOgImageHeight.content = '200';
document.head.appendChild(galleryOgImageHeight);

// Cleanup on component unmount
return () => {
  document.title = 'Vastushilp Interior Designs : Best Interior Designers in Pune | Gallery'; // Reset the title if needed
  document.head.removeChild(galleryMetaDescription);
  document.head.removeChild(galleryMetaKeywords);
  document.head.removeChild(galleryCanonicalLink);
  document.head.removeChild(galleryOgTitle);
  document.head.removeChild(galleryOgDescription);
  document.head.removeChild(galleryOgKeywords);
  document.head.removeChild(galleryOgUrl);
  document.head.removeChild(galleryOgSiteName);
  document.head.removeChild(galleryOgImage);
  document.head.removeChild(galleryOgImageSecure);
  document.head.removeChild(galleryOgImageWidth);
  document.head.removeChild(galleryOgImageHeight);
};

},[])
  return (
    <div>

<Gall />

    </div>
  )
}

export default Gallery