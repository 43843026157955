import React from 'react'
import classes from './Cr.module.css';

import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';



import b from './../../Assests/x8.jpg'; 
import b1 from './../../Assests/zz17.jpg'
import b2 from './../../Assests/z18.webp'


const Wwr = () => {
  return (
    
    <div className={classes.para1e}>

   
      
       <div className={classes.ee}>

        <Fade>
                 <div className={classes.flexe}>
                {/* <center>  <img src={b} className={classes.A2e}></img> </center> */}
           
                <center>  <img src={b2} className={classes.A2e}></img> </center>  
                 </div>
                 </Fade>


       <div className={classes.lefte}>
       <div className={classes.Ae}>
         <Fade bottom> About Our Firm - Vastushilp Design </Fade> 
          </div>
 
        
                 <Slide left>
                 <div>
                 <p className={classes.paraSe}><br/>&emsp;&emsp;
Vastushilp Design, a prominent interior design firm in 
Pune, has been dedicated to delivering exceptional and 
personalized homes since its establishment in 2016. 
<br/><br/>
&emsp;&emsp;Its portfolio encompasses a wide range of interior design 
projects, including residential spaces, exterior 
renovations, and interior refurbishments, and it offers 
professional services to its clients.
As your home is one of the most significant investments 
in your life and the starting point of your life story, Vastushilp Design goes above and beyond to ensure that 
it is the best it can be. 
<br/><br/>
&emsp;&emsp;
The firm strives to create a warm 
and inviting atmosphere in your home, bringing your 
vision to life. The team of experienced interior designers 
is well-versed in the latest trends in interior design, and it 
makes the most of every inch of space available. The 
team's top priority is client satisfaction, and it not only 
delivers excellent designs but also assists clients 
throughout the execution process.
<br/><br/>
&emsp;&emsp;
At 
Vastushilp Design, the team creates homes that 
can be cherished for generations, focusing on key 
themes such as establishing a sense of place, 
enhancing living experiences, embracing 
technological advancements, providing thoughtful 
amenities, and ensuring safety and security 
are a reflection of her commitment to the environment, and she strives to incorporate eco-friendly materials and practices into all her projects.


               </p>    </div></Slide>
              

                 </div>


                 
                 </div>

        
          
         
            <div>
          
          
         
        
       </div>
       </div>
          
             
  )
}
export default Wwr;