import React from 'react'
import classes from "./Hwb.module.css"

import A1 from "./../../Assests/x5.jpg";


import A2 from "./../../Assests/z25.webp";
import A3 from "./../../Assests/z82.webp";
import A4 from "./../../Assests/z34.webp";

const Hwb = () => {
  return (
    <div className={classes.Hwb}>

 <div className={classes.Hwb1}>
    
    <h1 className={classes.Hwbh1}> Our Vision</h1> 
    <p1 className={classes.Hwbp1}> 
    At Vastushilp Interior Design, our vision is to establish ourselves as the foremost provider of innovative and captivating interior design solutions in Pune. We are dedicated to setting unprecedented standards within the industry by consistently delivering extraordinary designs that not only meet but exceed the expectations of our clients.
 
     </p1>

    </div>
<br></br>
 <div className={classes.Hwb2}>
    
    <h1 className={classes.Hwbh2}> Our Mission </h1> 
    <p1 className={classes.Hwbp2}> 
At Vastushilp Interior Design, our mission is to craft captivating and functional interior spaces that enrich the lives of our clients. Our driving force is the passion to provide extraordinary design solutions that go beyond expectations, leaving a lasting impression on every project we undertake.
  
     </p1>

    </div>



</div>
    
    
    

  )
}

export default Hwb