import React, { useState } from 'react';
import classes from './Gallery.module.css';
import Fade from 'react-reveal/Fade';
import styles from './../Packages/Pack.module.css'




import Gm4 from './../../Assests/gm4.mp4';
import Gm5 from './../../Assests/gm5.mp4';
import Gm6 from './../../Assests/gm6.mp4';
import Gm7 from './../../Assests/gm7.mp4';
import Gm8 from './../../Assests/gm8.mp4';
import Gm9 from './../../Assests/gm9.mp4';
import Gm10 from './../../Assests/gm10.mp4';
import Gm11 from './../../Assests/gm11.mp4';
import Gm12 from './../../Assests/gm12.mp4';
import Gm13 from './../../Assests/gm13.mp4';
import Gm14 from './../../Assests/gm14.mp4';
import Gm15 from './../../Assests/gm15.mp4';

import Gm16 from './../../Assests/gm16.mp4';
import Gm17 from './../../Assests/gm17.mp4';
import Gm18 from './../../Assests/gm18.mp4';
import Gm19 from './../../Assests/gm19.mp4';
import Gm20 from './../../Assests/gm20.mp4';






import z1 from './../../Assests/z1.webp';
import z2 from './../../Assests/z2.webp';
import z3 from './../../Assests/z3.webp';
import z8 from './../../Assests/z8.webp';
import z10 from './../../Assests/z10.webp';

import z12 from './../../Assests/z12.webp';
import z14 from './../../Assests/z14.webp';
import z18 from './../../Assests/z18.webp';
import z19 from './../../Assests/z19.webp';
import z20 from './../../Assests/z20.webp';
import z24 from './../../Assests/z24.webp';
import z25 from './../../Assests/z25.webp';
import z30 from './../../Assests/z30.webp';
import z34 from './../../Assests/z34.webp';
import z28 from './../../Assests/z28.webp';
import z35 from './../../Assests/z35.webp';
import z40 from './../../Assests/z40.webp';
import z46 from './../../Assests/z46.webp';
import z48 from './../../Assests/z48.webp';
import z49 from './../../Assests/z49.webp';
import z52 from './../../Assests/z52.webp';
import z54 from './../../Assests/z54.webp';
import z56 from './../../Assests/z56.webp';
import z57 from './../../Assests/z57.webp';
import z60 from './../../Assests/z60.webp';
import z62 from './../../Assests/z62.webp';
import z67 from './../../Assests/z67.webp';
import z61 from './../../Assests/z61.webp';
import z63 from './../../Assests/z63.webp';
import z64 from './../../Assests/z64.webp';
import z65 from './../../Assests/z65.webp';
import z66 from './../../Assests/z66.webp';
import z68 from './../../Assests/z68.webp';
import z69 from './../../Assests/z69.webp';
import z70 from './../../Assests/z70.webp';
import z72 from './../../Assests/z72.webp';
import z74 from './../../Assests/z74.webp';

import z76 from './../../Assests/z76.webp';
import z79 from './../../Assests/z79.webp';
import z80 from './../../Assests/z80.webp';
import z81 from './../../Assests/z81.webp';
import z82 from './../../Assests/z82.webp';
import z83 from './../../Assests/z83.webp';
import z84 from './../../Assests/z84.webp';
import z85 from './../../Assests/z85.webp';
import z86 from './../../Assests/z86.webp';
import z87 from './../../Assests/z87.webp';
import z88 from './../../Assests/z88.webp';
import z89 from './../../Assests/z89.webp';
import z90 from './../../Assests/z90.webp';
import z91 from './../../Assests/z91.webp';
import z93 from './../../Assests/z93.webp';
import z94 from './../../Assests/z94.webp';
import z95 from './../../Assests/z95.webp';
import z96 from './../../Assests/z96.webp';
import zdr from './../../Assests/zdr.webp';

import zz1 from './../../Assests/zz1.jpg';
import zz2 from './../../Assests/zz2.jpg';
import zz3 from './../../Assests/zz3.jpg';
import zz4 from './../../Assests/zz4.jpg';
import zz5 from './../../Assests/zz5.jpg';
import zz6 from './../../Assests/zz6.jpg';
import zz7 from './../../Assests/zz7.jpg';
import zz8 from './../../Assests/zz8.jpg';
import zz9 from './../../Assests/zz9.jpg';
import zz10 from './../../Assests/zz10.jpg';
import zz11 from './../../Assests/zz11.jpg';
import zz12 from './../../Assests/zz12.jpg';
import zz13 from './../../Assests/zz13.jpg';
import zz14 from './../../Assests/zz14.jpg';
import zz15 from './../../Assests/zz15.jpg';
import zz16 from './../../Assests/zz16.jpg';
import zz17 from './../../Assests/zz17.jpg';
import zz18 from './../../Assests/zz18.jpg';
import zz19 from './../../Assests/zz19.jpg';
import zz20 from './../../Assests/zz20.jpg';
import zz21 from './../../Assests/zz21.jpg';
import zz22 from './../../Assests/zz22.jpg';
import zz23 from './../../Assests/zz23.jpg';
import zz24 from './../../Assests/zz24.jpg';
import zz25 from './../../Assests/zz25.jpg';
import zz26 from './../../Assests/zz26.jpg';
import zz27 from './../../Assests/zz27.jpg';




const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    


    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
   

    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
   

    };




    const imageList = [z25, /*z30,*/ z34, z28, z35, /*z40,*/ z46, /*z48, z49,*/ /*zz1,*/ /*zz3,*/ zz5, /*zz6*/, zz7, z52, /*z54, z56,*/  /*z63,*/ z64, /*z65,*/ z66, z68, zz8, zz9, zz10, zz11, zz12, zz13, zz14, zz15, zz16, zz17, zz18, zz19, zz20, z57, /*z60,*/ z62, z67, z61, zz21, zz22, zz23, zz24, /*zz25,*/ zz26, /*zz27, z1,*/ zz2, zz4, z2, /*z3, z8,*/ z10,  /*z12, z14,*/ z18, /*z19,z20,*/
   /*z69*/, z70, z72, z74, z76, z79, z80, z81, z82, z83, z84, z85, z24, /*z86,*/ z87,/* z88, z89,*/ z90, /*z91,*/ z93, /*z94*/, z95, z96, zdr];




    const videoList = [Gm16, Gm17, Gm18, Gm19, Gm20, Gm4, Gm5, Gm6, Gm7, Gm8,/*Gm9,*/Gm10,/*Gm11,*/Gm12, Gm13, Gm14/*Gm15*/];

    const openImage = (image) => {
        setSelectedImage(image);
        setSelectedVideo(null); // Ensure no video is selected when an image is clicked
    };

    const openVideo = (video) => {
        setSelectedVideo(video);
        setSelectedImage(null); // Ensure no image is selected when a video is clicked
    };

    const closeMedia = () => {
        setSelectedImage(null);
        setSelectedVideo(null);
    };

    return (
        <div className={classes.j1}>

            <br /><br />
            <h1 className={classes.ih31}>Gallery</h1>

            <br />
            <center>
            <div className={styles.left}>
                <button className={r1 ? styles.AccBtn1 : styles.AccBtn} onClick={clkHnd1}>IMAGES</button>
                <button className={r2 ? styles.AccBtn1 : styles.AccBtn} onClick={clkHnd2}>VIDEOS</button>
     
            </div></center>

 {r1 && <div className={classes.r}>
            <div className={classes.nj}>
               
                <div className={classes.thumbbar}>
                    {imageList.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Thumbnail ${index + 1}`}
                            className={classes.gi}
                            onClick={() => openImage(image)}
                        />
                    ))}
                </div>
          
            </div>
            </div>}




 {r2 && <div className={classes.r}>
    <div className={classes.nj}>
                  <div className={classes.thumbbar}>

                    {videoList.map((video, index) => (
                        <video
                            key={index}
                            src={video}
                            className={classes.gi}
                            onClick={() => openVideo(video)}
                            controls
                            muted
                        />
                    ))}
                </div>
                </div>

       {selectedVideo && (
                            <video
                                src={selectedVideo}
                                className={classes.selectedimg}
                                controls
                                autoPlay
                                muted
                            />
                        )}

                  {(selectedImage) && (
                <div className={classes.modaloverlay} onClick={closeMedia}>
                    <div className={classes.modalcontent}>
                        {selectedImage && (
                            <img
                                src={selectedImage}
                                alt="Selected Image"
                                className={classes.selectedimg}
                            />
                        )}

                 
                    </div>
                </div>
            )}       


  </div>}


            {(selectedImage) && (
                <div className={classes.modaloverlay} onClick={closeMedia}>
                    <div className={classes.modalcontent}>
                        {selectedImage && (
                            <img
                                src={selectedImage}
                                alt="Selected Image"
                                className={classes.selectedimg}
                            />
                        )}

                 
                    </div>
                </div>
            )}
        </div>
    );
}

export default Gallery;
