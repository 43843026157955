import React from 'react';
import { useState } from "react";
import "./Header.css";

import { FaHouseDamage } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { FaGripHorizontal } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";
import {FaPhoneAlt} from 'react-icons/fa'
import {FaRegEnvelope } from 'react-icons/fa'

import Fb from "./../../Assests/facebook.png"
import Insta from "./../../Assests/instagram.png"
import Yt from "./../../Assests/youtube.png"
import In from "./../../Assests/lkdin.png"

import  {Link}  from 'react-router-dom';

import img from "./../../Assests/logo.png";

function Navi() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

    const resetNavbar = () => {
      setActive('nav__menu');
      setIcon('nav__toggler');
   
  };
  return (
    <div className="nasv">
    <nav className="nav">

<div className='header'>

    <div className='h1'><a href="tel:9762439208" className='mail mail1'>Phone : +91 9762439208</a> 
    &emsp;&emsp;&emsp;
 <a href="mailto:vastushilp@outlook.com" className='mail mail2'>Email : vastushilp@outlook.com</a>
 </div>
    
    <div className='ss'>

    <a href='https://www.facebook.com/people/Vastushilp-Design-by-Mrunnali-Gurav/100089163747457/' ><img src={Fb} className='ims'></img></a>
    <a href='https://www.instagram.com/interiorsby_mrunnaligurav/' ><img src={Insta} className='ims'></img></a>
    <a href='https://www.youtube.com/@InteriorsbyMrunnaliGurav' ><img src={Yt} className='ims'></img></a>


    </div>
</div>

    <div className='header2'> 
     <div className='logo2'>
      <img src={img} className='img1'></img>
     </div>     

<div className='nd'>

<div className={active}>
      <ul className='ul'>
      <li className="list"><Link to='/' className="list" onClick={resetNavbar}><FaHouseDamage className='icon2'/> &nbsp;Home</Link></li>
      <li className="list"><Link to='/About' className="list" onClick={resetNavbar}><FaAddressCard className='icon2'/>&nbsp;About</Link></li>
      <li className="list"><Link to='/Packages' className="list" onClick={resetNavbar}><FaList className='icon2'/> &nbsp;Packages</Link></li>
      <li className="list"><Link to='/Gallery' className="list" onClick={resetNavbar}><FaList className='icon2'/> &nbsp;Gallery</Link></li>
      <li className="list"><Link to='/Contact' className="list" onClick={resetNavbar}><FaAddressBook className='icon2'/>&nbsp;Contact us</Link></li>
      

      </ul>
      </div>
      </div>
    

  


      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      </div>
    </nav>
    </div>
  );
}

export default Navi;








