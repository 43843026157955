import React from "react";
import classes from  "./Abt.module.css"

import img1 from "./../../Assests/zy3.gif";
import img2 from "./../../Assests/zy2.gif";
import img3 from "./../../Assests/zy1.gif";


const Abt = () => {

return(
<div className={classes.Abt}>

<div>        <h1 className={classes.ih31}>We Featured</h1>

<br/> </div>
<div  className={classes.Abt12}>
<div className={classes.Abt1}>
    <img src={img1} className={classes.Abt1i1}/>
    <p  className={classes.Abt1p}> Customized Desing</p>
</div>


<div className={classes.Abt1}>
    <img src={img2} className={classes.Abt1i}/>
    <p  className={classes.Abt1p}> Transparent Pricing </p>
</div>


<div className={classes.Abt1}>
    <img src={img3} className={classes.Abt1i}/>
    <p  className={classes.Abt1p}> Best Technology </p>
</div>
</div>

</div>
)

}


export default Abt