import { useState } from 'react';
import classes from './Pack.module.css';
import styles from './Pack.module.css';
const Sepro = () => {
    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    const [r3, setR3] = useState(false);


    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
        setR3(false);

    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
        setR3(false);

    };

    const clkHnd3 = () => {
        setR1(false);
        setR2(false);
        setR3(true);

    };



    return (
        <div className='AccHold'>

<br/><br/>
        <h1 className={classes.ih31}>Packages</h1>

<br/>

            <center>
                <br></br>
    <div className={styles.left}>
    <button className={r1 ? styles.AccBtn1 : styles.AccBtn} onClick={clkHnd1}>1 BHK</button>
    <button className={r2 ? styles.AccBtn1 : styles.AccBtn} onClick={clkHnd2}>2 BHK</button>
    <button className={r3 ? styles.AccBtn1 : styles.AccBtn} onClick={clkHnd3}>3 BHK</button>
</div>
                <div className={classes.r}>
                    {r1 && <div className={classes.r}>

                        <div className={classes.p1}>
                            <h4 className={classes.hed2}>Packages For 1Bhk</h4>

                            <div className={classes.fl}>

                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        {/*<img src={A} className="imgs"></img> */}
                                        <h4 className={classes.hd}> STANDARD </h4>

                                        <ul className={classes.wu}>

                                            <span className={classes.dd}><span className={classes.kkd}>₹345,000 </span>&nbsp; ₹285,000</span>

                                            <hr />
                                            <br />

                                            <span className={classes.dd}> LIVING & DINING </span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <hr />

                                            <br />
                                            <span className={classes.dd}> MASTER BEDROOM </span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm)-1 No</li>
                                            <hr />
                                            <br />
                                            <span className={classes.dd}> KITCHEN </span>
                                            <hr />

                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <hr />
                                            <br />
                                            <span className={classes.dd}> FURNISHING </span>
                                            <hr />
                                            <br />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>

                                        </ul>

                                    </div>
                                </div>


                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Classic</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹460,000 </span>&nbsp; ₹380,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Sofa Set – 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <li>Centre Table – 1 No</li>
                                            <li>Dining Table (120cm x 90cm) with 4 Chairs</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <li>Wallpaper / Dark Highlight Color – 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                        </ul>
                                    </div>
                                </div>


                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Premium</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹510,000 </span> &nbsp;₹420,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Basic False Ceiling with 6 Down Lights & Warm LED Light</li>
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <li>Basic False Ceiling with 4 Down Lights & LED Warm Light</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <li>HOB (60cm x 60cm) - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                            <li>Bedsheet & pillow Cover set – 1 No</li>
                                            <hr />
                                            <br />
                                            <span className={classes.dd}>PAINTING</span>
                                            <hr />
                                            <li>Entire Flat Oil Bond Painting (Exclude Balcony & Bathrooms)</li> </ul>
                                    </div>
                                </div>



                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Elite</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹585,000 </span> &nbsp; ₹495,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <li>Carpet - 1 No</li>
                                            <li>Wall painting / Wall Art - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>Bed Back Cushion - 1 No</li>
                                            <li>Bed side table (40cm x 80cm) - 2 Nos</li>
                                            <li>Wall Paper (Bed Back Wall) - 1 No</li>
                                            <li>Wall Lamp / Hanging Lamp - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>G-Section Profile Handle</li>
                                            <li>Premium Hardware</li>
                                            <li>Chimney - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                            <hr />
                                            <br />
                                            <span className={classes.dd}>PAINTING</span>
                                            <hr />
                                            <li>Entire Flat Luster Painting (Exclude Balcony & Bathrooms)</li>
                                        </ul>
                                    </div>
                                </div>


                            </div>

                            <h6 className={classes.hedrr}>
                                Materials : BWR ply ISI mark (For kitchen), Commercial Ply for Other Rooms External Laminate 1 mm& Internal laminate 0.8 mm off white, Premium channels (Kitchen) Standard Hardware
                                <br />
                                <span className={classes.hedr}>* Terms & Conditions Apply </span></h6>

                        </div>


                    </div>}








                    {r2 && <div className={classes.r}>

                        <div className={classes.p2}>
                            <h4 className={classes.hed2}>Packages For 2Bhk</h4>

                            <div className={classes.fl}>


                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Standard</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹480,000</span> &nbsp;  ₹400,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                        </ul>
                                    </div>
                                </div>


                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Classic</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹630,000 </span> &nbsp; ₹520,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Sofa Set – 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <li>Centre Table – 1 No</li>
                                            <li>Dining Table (120cm x 90cm) with 4 Chairs</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <li>Wallpaper / Dark Highlight Color – 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                        </ul>
                                    </div>
                                </div>



                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Premium</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹820,000 </span> &nbsp; ₹675,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Basic False Ceiling with 6 Down Lights & Warm LED Light</li>
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <li>Basic False Ceiling with 4 Down Lights & LED Warm Light</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <li>HOB (60cm x 60cm) - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                            <li>Bedsheet & pillow Cover set – 1 No</li>
                                            <hr />
                                            <br />
                                            <span className={classes.dd}>PAINTING</span>
                                            <hr />
                                            <li>Entire Flat Luster Painting (Exclude Balcony & Bathrooms)</li>
                                        </ul>
                                    </div>
                                </div>


                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Elite</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹930,000 </span> &nbsp; ₹775,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <li>Carpet - 1 No</li>
                                            <li>Wall painting / Wall Art - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>Bed Back Cushion - 1 No</li>
                                            <li>Bed side table (40cm x 80cm) - 2 Nos</li>
                                            <li>Wall Paper (Bed Back Wall) - 1 No</li>
                                            <li>Wall Lamp / Hanging Lamp - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>G-Section Profile Handle</li>
                                            <li>Premium Hardware</li>
                                            <li>Chimney - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                            <hr />
                                            <br />
                                            <span className={classes.dd}>PAINTING</span>
                                            <hr />
                                            <li>Entire Flat Luster Painting (Exclude Balcony & Bathrooms)</li>
                                        </ul>
                                    </div>
                                </div>






                            </div>


                            <h6 className={classes.hedrr}>
                                Materials : BWR ply ISI mark (For kitchen), Commercial Ply for Other Rooms External Laminate 1 mm& Internal laminate 0.8 mm off white, Premium channels (Kitchen) Standard Hardware
                                <br />
                                <span className={classes.hedr}>* Terms & Conditions Apply </span></h6>
                        </div>

                    </div>}



                    {r3 && <div className={classes.r}>

                        <div className={classes.p3}>
                            <h4 className={classes.hed2}>Packages For 3Bhk</h4>

                            <div className={classes.fl}>


                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Standard</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹6,70,000 </span> &nbsp; ₹5,60,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                        </ul>
                                    </div>
                                </div>



                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Classic</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹8,20,000 </span> &nbsp;₹6,80,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Sofa Set – 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <li>Centre Table – 1 No</li>
                                            <li>Dining Table (120cm x 90cm) with 4 Chairs</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <li>Wallpaper / Dark Highlight Color – 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                        </ul>
                                    </div>
                                </div>


                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Premium</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹10,30,000 </span> &nbsp; ₹8,60,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Basic False Ceiling with 6 Down Lights & Warm LED Light</li>
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <li>Basic False Ceiling with 4 Down Lights & LED Warm Light</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>Basic Handle</li>
                                            <li>Standard Hardware</li>
                                            <li>HOB (60cm x 60cm) - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                            <li>Bedsheet & pillow Cover set – 1 No</li>
                                            <span className={classes.dd}>PAINTING</span>
                                            <hr />

                                            <li>Entire Flat Oil Bond Painting (Exclude Balcony & Bathrooms)</li>

                                        </ul>
                                    </div>
                                </div>



                                <div className={classes.card}>
                                    <div className={classes.c1}>
                                        <h4 className={classes.hd}>Elite</h4>

                                        <ul className={classes.wu}>
                                            <span className={classes.dd}><span className={classes.kkd}>₹11,70,000 </span> &nbsp; ₹9,75,000</span>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>LIVING & DINING</span>
                                            <hr />
                                            <br />
                                            <li>Shoe rack (90 cm x 90 cm) - 1 No</li>
                                            <li>Lower LCD unit - (150cm x 60cm) - 1 No</li>
                                            <li>Wallpaper – 1 No</li>
                                            <li>Carpet - 1 No</li>
                                            <li>Wall painting / Wall Art - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>MASTER BEDROOM</span>
                                            <hr />
                                            <li>Queen size bed with storage (165cm x 195cm) - 1 No</li>
                                            <li>Bed Back Cushion - 1 No</li>
                                            <li>Bed side table (40cm x 80cm) - 2 Nos</li>
                                            <li>Wall Paper (Bed Back Wall) - 1 No</li>
                                            <li>Wall Lamp / Hanging Lamp - 1 No</li>
                                            <li>5" mattress - 1 No with two PillowsŪ</li>
                                            <li>Double wardrobe (180cm x 210cm) - 1 No</li>
                                            <li>Loft (Above Wardrobe) – 1 No</li>
                                            <li>Dressing Mirror with storage (60cm x 210cm) - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>KITCHEN</span>
                                            <hr />
                                            <li>Shutter Below Platform (210cm x 75cm)</li>
                                            <li>Trolleys</li>
                                            <li>Cup saucer Basket</li>
                                            <li>Thali- Plate Basket</li>
                                            <li>Perforated Cutlery Basket</li>
                                            <li>Multipurpose Single Pull-out (3 compartments)</li>
                                            <li>3 nos Plain Basket</li>
                                            <li>Overhead Cabinet (180cm x 60cm)</li>
                                            <li>G-Section Profile Handle</li>
                                            <li>Premium Hardware</li>
                                            <li>Chimney - 1 No</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>FURNISHING</span>
                                            <hr />
                                            <li>Curtain For Living Room and Bedroom Windows - 1 No Each</li>
                                            <hr />
                                            <br />

                                            <span className={classes.dd}>PAINTING</span>
                                            <hr />

                                            <li>Entire Flat Luster Painting (Exclude Balcony & Bathrooms)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <h6 className={classes.hedrr}>
                                Materials : BWR ply ISI mark (For kitchen), Commercial Ply for Other Rooms External Laminate 1 mm& Internal laminate 0.8 mm off white, Premium channels (Kitchen) Standard Hardware
                                <br /><br /><span className={classes.hedr}>* Terms & Conditions Apply </span></h6>
                        </div>

                    </div>}


                </div>
            </center>
        </div>

    )
}

export default Sepro;