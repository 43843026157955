import React, { useRef, useEffect } from "react";
import classes from "./Vdo.module.css";

import i1 from "./../../Assests/i1.mp4";
import i2 from "./../../Assests/i2.mp4";
import i3 from "./../../Assests/i3.mp4";
import i4 from "./../../Assests/i4.mp4";
import i6 from "./../../Assests/i6.mp4";
import i7 from "./../../Assests/i7.mp4";
import i8 from "./../../Assests/i8.mp4";


const Vdo = () => {
  const videoRef = useRef(null);
  const videos = [i8, i2, i3, i4, i6, i7, i1];
  let currentVideoIndex = 0;

  useEffect(() => {
    const videoElement = videoRef.current;

    const playNextVideo = () => {
      currentVideoIndex = (currentVideoIndex + 1) % videos.length;
      videoElement.src = videos[currentVideoIndex];
      videoElement.play();
    };

    videoElement.addEventListener("ended", playNextVideo);

    return () => {
      videoElement.removeEventListener("ended", playNextVideo);
    };
  }, []);

  return (
    <div className={classes.vdo}>
      <video autoPlay muted className={classes.video} ref={videoRef}>
        <source src={i8} type="video/mp4" />
        {/* Sources will be set dynamically by JavaScript */}
      </video>
    </div>
  );
};

export default Vdo;
