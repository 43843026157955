import React from 'react'
import classes from "./Steps.module.css"

import Meeting from "./../../Assests/a5.svg";
import img2 from "./../../Assests/a4.svg";
import img3 from "./../../Assests/a3.svg";
import img4 from "./../../Assests/a1.svg";
import img5 from "./../../Assests/a2.svg";
import img6 from "./../../Assests/a6.svg";

const Steps = () => {
  return (
    <div className={classes.steps}>

        <h1 className={classes.ih3}>How To Communicate With Us</h1>

<br/>

    <div className={classes.steps3}>


<div className={classes.steps1}> 

<div className={classes.on}>
  <img src={Meeting} className={classes.img} alt="Vastushilp Desing" />
  <br/>
  <span className={classes.so}> Meeting </span>
</div>

<div className={classes.on}>
  <img src={img2} className={classes.img} alt="Vastushilp Desing" />
    <br/>
  <span className={classes.so}> Planning</span>
</div>

<div className={classes.on}>
  <img src={img3} className={classes.img} alt="Vastushilp Desing" />
    <br/>
  <span className={classes.so}> Selection</span>
</div>

</div>


<br/><br/><br/>

<div className={classes.steps1}> 

<div className={classes.on}>
  <img src={img4} className={classes.img} alt="Vastushilp Desing" />
    <br/>
  <span className={classes.so}> Approval </span>
</div>

<div className={classes.on}>
  <img src={img6} className={classes.img} alt="Vastushilp Desing" />
   <br/>
  <span className={classes.so}> Executions </span>
</div>

<div className={classes.on}>
  <img src={img5} className={classes.img} alt="Vastushilp Desing" />
   <br/>
  <span className={classes.so}> Finishing </span>
</div>
</div>

<br/> 

<div className={classes.steps1}> 




</div>

</div>
    </div>
  )
}

export default Steps