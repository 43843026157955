import React, {useEffect} from 'react'
import Pack from './../Component/Packages/Pack'

const Packages = () => {

    useEffect(()=>{
    window.scrollTo(0,0)

// Package Page Meta Tags
document.title = 'Vastushilp Interior Designs : Best Interior Designers in Pune | Our Packages ';

// Set meta description dynamically
const packageMetaDescription = document.createElement('meta');
packageMetaDescription.name = 'description';
packageMetaDescription.content = 'Discover the diverse interior design packages offered by Vastushilp Interior Designs. From residential to commercial spaces, find the perfect package that suits your needs and budget.';
document.head.appendChild(packageMetaDescription);

// Set meta keywords dynamically
const packageMetaKeywords = document.createElement('meta');
packageMetaKeywords.name = 'keywords';
packageMetaKeywords.content = 'Vastushilp Interior Designs Packages, Interior Design Packages Pune, Residential Interior Design Packages, Commercial Interior Design Packages, Best Interior Designers in Pune, Vastu Shastra Design, Pune Interior Decorators, Custom Interior Design Packages, Affordable Interior Design Packages';
document.head.appendChild(packageMetaKeywords);

// Set canonical link dynamically
const packageCanonicalLink = document.createElement('link');
packageCanonicalLink.rel = 'canonical';
packageCanonicalLink.href = 'https://vastushilpdesigns.com/packages';
document.head.appendChild(packageCanonicalLink);

// Set Open Graph title dynamically
const packageOgTitle = document.createElement('meta');
packageOgTitle.property = 'og:title';
packageOgTitle.content = 'Vastushilp Interior Designs : Best Interior Designers in Pune | Our Packages';
document.head.appendChild(packageOgTitle);

// Set Open Graph description dynamically
const packageOgDescription = document.createElement('meta');
packageOgDescription.property = 'og:description';
packageOgDescription.content = 'Discover the diverse interior design packages offered by Vastushilp Interior Designs. From residential to commercial spaces, find the perfect package that suits your needs and budget.';
document.head.appendChild(packageOgDescription);

// Set Open Graph keywords dynamically
const packageOgKeywords = document.createElement('meta');
packageOgKeywords.property = 'og:keywords';
packageOgKeywords.content = 'Vastushilp Interior Designs Packages, Interior Design Packages Pune, Residential Interior Design Packages, Commercial Interior Design Packages, Best Interior Designers in Pune, Vastu Shastra Design, Pune Interior Decorators, Custom Interior Design Packages, Affordable Interior Design Packages';
document.head.appendChild(packageOgKeywords);

// Set Open Graph URL dynamically
const packageOgUrl = document.createElement('meta');
packageOgUrl.property = 'og:url';
packageOgUrl.content = 'https://vastushilpdesigns.com/packages';
document.head.appendChild(packageOgUrl);

// Set Open Graph site name dynamically
const packageOgSiteName = document.createElement('meta');
packageOgSiteName.property = 'og:site_name';
packageOgSiteName.content = 'Vastushilp Interior Designs';
document.head.appendChild(packageOgSiteName);

// Set Open Graph image dynamically
const packageOgImage = document.createElement('meta');
packageOgImage.property = 'og:image';
packageOgImage.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(packageOgImage);

// Set Open Graph secure image URL dynamically
const packageOgImageSecure = document.createElement('meta');
packageOgImageSecure.property = 'og:image:secure_url';
packageOgImageSecure.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(packageOgImageSecure);

// Set Open Graph image width dynamically
const packageOgImageWidth = document.createElement('meta');
packageOgImageWidth.property = 'og:image:width';
packageOgImageWidth.content = '300';
document.head.appendChild(packageOgImageWidth);

// Set Open Graph image height dynamically
const packageOgImageHeight = document.createElement('meta');
packageOgImageHeight.property = 'og:image:height';
packageOgImageHeight.content = '200';
document.head.appendChild(packageOgImageHeight);

// Cleanup on component unmount
return () => {
  document.title = 'Default Title'; // Reset the title if needed
  document.head.removeChild(packageMetaDescription);
  document.head.removeChild(packageMetaKeywords);
  document.head.removeChild(packageCanonicalLink);
  document.head.removeChild(packageOgTitle);
  document.head.removeChild(packageOgDescription);
  document.head.removeChild(packageOgKeywords);
  document.head.removeChild(packageOgUrl);
  document.head.removeChild(packageOgSiteName);
  document.head.removeChild(packageOgImage);
  document.head.removeChild(packageOgImageSecure);
  document.head.removeChild(packageOgImageWidth);
  document.head.removeChild(packageOgImageHeight);
};


},[])
  return (
    <div>
<Pack/>

    </div>
  )
}

export default Packages