import React, {useEffect} from 'react'
import Info from "../Component/About/Info"


import Hwb from "../Component/Home/Hwb";
import Inf from "../Component/About/Info2"

const About = () => {

useEffect(()=>{
  window.scrollTo(0,0)



// About Page Meta Tags
document.title = ' Vastushilp Interior Designs : Best Interior Designers in Pune | About Us ';

// Set meta description dynamically
const aboutMetaDescription = document.createElement('meta');
aboutMetaDescription.name = 'description';
aboutMetaDescription.content = 'Learn more about Vastushilp Interior Designs, the best interior designers in Pune. Discover our expertise in creating beautiful, functional, and Vastu-compliant interiors.';
document.head.appendChild(aboutMetaDescription);

// Set meta keywords dynamically
const aboutMetaKeywords = document.createElement('meta');
aboutMetaKeywords.name = 'keywords';
aboutMetaKeywords.content = 'About Vastushilp Interior Designs, Best Interior Designers in Pune, Vastu Shastra, Residential Interiors, Commercial Interiors, Interior Design Services Pune, Interior Design Expertise, Interior Decorators Pune';
document.head.appendChild(aboutMetaKeywords);

// Set canonical link dynamically
const aboutCanonicalLink = document.createElement('link');
aboutCanonicalLink.rel = 'canonical';
aboutCanonicalLink.href = 'https://vastushilpdesigns.com/about';
document.head.appendChild(aboutCanonicalLink);

// Set Open Graph title dynamically
const aboutOgTitle = document.createElement('meta');
aboutOgTitle.property = 'og:title';
aboutOgTitle.content = ' Vastushilp Interior Designs : Best Interior Designers in Pune | About Us ';
document.head.appendChild(aboutOgTitle);

// Set Open Graph description dynamically
const aboutOgDescription = document.createElement('meta');
aboutOgDescription.property = 'og:description';
aboutOgDescription.content = 'Learn more about Vastushilp Interior Designs, the best interior designers in Pune. Discover our expertise in creating beautiful, functional, and Vastu-compliant interiors.';
document.head.appendChild(aboutOgDescription);

// Set Open Graph keywords dynamically
const aboutOgKeywords = document.createElement('meta');
aboutOgKeywords.property = 'og:keywords';
aboutOgKeywords.content = 'About Vastushilp Interior Designs, Best Interior Designers in Pune, Vastu Shastra, Residential Interiors, Commercial Interiors, Interior Design Services Pune, Interior Design Expertise, Interior Decorators Pune';
document.head.appendChild(aboutOgKeywords);

// Set Open Graph URL dynamically
const aboutOgUrl = document.createElement('meta');
aboutOgUrl.property = 'og:url';
aboutOgUrl.content = 'https://vastushilpdesigns.com/about';
document.head.appendChild(aboutOgUrl);

// Set Open Graph site name dynamically
const aboutOgSiteName = document.createElement('meta');
aboutOgSiteName.property = 'og:site_name';
aboutOgSiteName.content = 'Vastushilp Interior Designs';
document.head.appendChild(aboutOgSiteName);

// Set Open Graph image dynamically
const aboutOgImage = document.createElement('meta');
aboutOgImage.property = 'og:image';
aboutOgImage.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(aboutOgImage);

// Set Open Graph secure image URL dynamically
const aboutOgImageSecure = document.createElement('meta');
aboutOgImageSecure.property = 'og:image:secure_url';
aboutOgImageSecure.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(aboutOgImageSecure);

// Set Open Graph image width dynamically
const aboutOgImageWidth = document.createElement('meta');
aboutOgImageWidth.property = 'og:image:width';
aboutOgImageWidth.content = '300';
document.head.appendChild(aboutOgImageWidth);

// Set Open Graph image height dynamically
const aboutOgImageHeight = document.createElement('meta');
aboutOgImageHeight.property = 'og:image:height';
aboutOgImageHeight.content = '200';
document.head.appendChild(aboutOgImageHeight);

// Cleanup on component unmount
return () => {
  document.title = ' Vastushilp Interior Designs : Best Interior Designers in Pune | About Us '; // Reset the title if needed
  document.head.removeChild(aboutMetaDescription);
  document.head.removeChild(aboutMetaKeywords);
  document.head.removeChild(aboutCanonicalLink);
  document.head.removeChild(aboutOgTitle);
  document.head.removeChild(aboutOgDescription);
  document.head.removeChild(aboutOgKeywords);
  document.head.removeChild(aboutOgUrl);
  document.head.removeChild(aboutOgSiteName);
  document.head.removeChild(aboutOgImage);
  document.head.removeChild(aboutOgImageSecure);
  document.head.removeChild(aboutOgImageWidth);
  document.head.removeChild(aboutOgImageHeight);
};



},[])

  return (
    <div>

<Inf/>
<Info/>
<Hwb />



    </div>
  )
}

export default About