import React from 'react'
import classes from './Info.module.css';

import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';



import b from './../../Assests/mg56.jpg'; 

import cert from "./../../Assests/ht.png";
import tech from "./../../Assests/engineer.png";
import set from "./../../Assests/arc.png";
import support from "./../../Assests/customer.png";




const Wwr = () => {
  return (
    
    <div className={classes.para1}>

       <div className={ classes.main}>
       
      
       <div className={classes.big}>
       <div className={classes.left}>
       <div className={classes.A}>
        <br/>
         <Fade bottom>  About Our Founder  </Fade> 
          </div>
 
        
                 <Slide left>
                 <div>
                 <p className={classes.paraS}><br/>&emsp;&emsp;
Women have been instrumental in breaking down barriers and advancing the interior design profession, paving the way for future generations of designers. They have brought fresh perspectives, innovative ideas, and a deep understanding of clients' needs and preferences to their work. 
<br/><br/>&emsp;&emsp;They have been successful not only as designers but also as business owners and leaders in the industry. Their entrepreneurial spirit and drive have allowed them to build successful design firms and lead large design teams. 
<br/><br/>&emsp;&emsp;One such visionary woman is<span className={classes.ceo}> Ms. Mrunali Gurav </span>,founder of <span className={classes.ceo}>Vastushilp Design</span>, who has made significant 
contributions to the interior design sector, and her impact will continue to be felt for years to come.Mrunali is an exceptional woman who has broken barriers and achieved success in the field of interior design.
<br/><br/>&emsp;&emsp; Her visionary approach and innovative ideas have made her a force to be reckoned with in the industry, and her contributions have been immense.
<br/><br/>&emsp;&emsp;As an entrepreneur, Mrunali has transformed the way people think about interior design. She has made it her mission to create spaces that are not only aesthetically pleasing but also functional and sustainable. Her designs 
are a reflection of her commitment to the environment, and she strives to incorporate eco-friendly materials and practices into all her projects.

               </p>    </div></Slide>
              

                 </div>
                 <Fade>
                 <div className={classes.flex}>
                 <center>  <img src={b} className={classes.A2}></img> </center> 
                 </div>
                 </Fade>
                 </div>

                  <div className={ classes.hold}>
          
         
            <div>
          
          
         
          <div className={classes.card}>
            <Fade leftt>
          <div className={classes.c1}>
                <img src={cert} className={classes.am}></img>
                <h3  className={classes.h3}>High Technology</h3>
                
                <p>Practicing Latest Technique.</p>
                  <p>With Quality Materials</p>
            </div>
            </Fade>
        
            <Fade right>
            <div className={classes.c1}>
                <img src={set} className={classes.am}></img>
                <h3 className={classes.h3}>Awards Achieve</h3>
                <p>The Iconic Platinum</p>
                <p>Woman Icon Of The Year</p>
            </div>
</Fade>
            <Fade right>
            <div className={classes.c1}>
                <img src={tech} className={classes.am}></img>
                <h3 className={classes.h3}>Best Suggestions</h3>
                <p>Architecture & Interiors</p>
                <p>Available from Monday - Saturday</p>
            </div>
            </Fade>
          </div>
       </div>
       </div>
          </div>
          </div>        
  )
}
export default Wwr;