import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, PrivateRoute} from 'react-router-dom';

import Home from  "./Pages/Home";
import About from  "./Pages/About";
import Packages from  "./Pages/Packages";
import Gallery from "./Pages/Gallery";
import Contact from './Pages/Contact';

import Header from "./Component/Nav/Header"
import Footer from "./Component/Footer/Footer"
import Wpp from "./Component/Footer/Wpp"


function App() {

return (
    <div className="App">
     
    <BrowserRouter>
<Header />
      <br/><br/><br/>
    <Routes>

<Route path="/" element={<Home />} />
<Route path="/About" element={<About />} />
<Route path="/Packages" element={<Packages />} />
<Route path="/Gallery" element={<Gallery />} />
<Route path="/contact" element={<Contact />} />

    </Routes>


    <Footer />

</BrowserRouter>

</div>
)
}

export default App;
