import React from 'react';
import classes from "./Wcy.module.css";
import { Link } from 'react-router-dom';

/*
import a1 from "./../../Assests/aa1.png";
import a2 from "./../../Assests/aa2.png";
import a4 from "./../../Assests/aa4.png";
import a3 from "./../../Assests/aa3.png";

*/

import a1 from "./../../Assests/dd1.png";
import a2 from "./../../Assests/dd2.png";
import a4 from "./../../Assests/dd4.png";
import a3 from "./../../Assests/dd3.png";

const Wcy = () => {
  return (
  <div className={classes.heddd}>



<div className={classes.left1}>

    <div className={classes.h1}>

<h1>
 <span className={classes.head}>  WHY </span> <br></br>
 <span className={classes.heads}> VASTUSHILP INTERIORS </span><br></br>
</h1>

    </div>

<br></br>

    <div className={classes.p1}>Team vastushilp takes the challenges in the design and implementation of projects have a determined mentality for making it more beautifying and eye catching. Our team have creative thinking and ability to surprise everyone which is the greatest asset. Team vastushilp presents itself as a team made up of close friends rather than defining as experts or professionals, striving to make our clients smile by observing the satisfaction on their faces.
We present ourselves as a team made up of not just experts but also close friends, striving to make our clients smile by observing the satisfaction on their faces.




    </div>

    <br></br>

    <div>

      <button className={classes.b1}><Link to='/Contact'  className={classes.be}>CONTACT US</Link></button>
    
    </div>

</div>
   





<div className={classes.right1}>
<br/>
<h5>
  <span className={classes.hd}>  What We Do...    </span> 
 </h5>
 <br></br>

<div className={classes.d}>
<img src={a1} className={classes.i1}/>

<div className={classes.d2}>
<h5  className={classes.h5}>
Interior Desinging
</h5>
<p className={classes.p5}>
Consider elements such as furniture dimensions, design aesthetics, and material selection for durability and timeless appeal in interior design.
</p>
</div>

</div>

<br />

<div className={classes.d}>
<img src={a2} className={classes.i1}/> 

<div className={classes.d2}>
<h5 className={classes.h5}>
Efficiency And performance
</h5>
<p className={classes.p5}>
Conduct performance tests to ensure elements meet specified flow rates, head pressures, and other parameters, ensuring seamless integration
</p>
</div>

</div>

<br />

<div className={classes.d}>
<img src={a3} className={classes.i1}/>

<div className={classes.d2}>
<h5 className={classes.h5}>
Testing And Quality
</h5>
<p className={classes.p5}>
Establish and execute comprehensive testing protocols to guarantee that interior design elements.
</p>
</div>

</div>
   

<br />   
<div className={classes.d}>
<img src={a4} className={classes.i1}/> 

<div className={classes.d2}>
<h5 className={classes.h5}>
Material Selection
</h5>
<p className={classes.p5}>
Select premium materials for the components to ensure durability against corrosion, abrasion, and wear and damage.
</p>
</div>

</div>
   
    </div>

    </div>
  
  )
}

export default Wcy