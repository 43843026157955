import React from 'react'
import classes from "./Offer.module.css"
import { Link } from 'react-router-dom';
import img1 from "./../../Assests/z62.webp";
import img2 from "./../../Assests/z68.webp";
import img3 from "./../../Assests/z84.webp";
import img4 from "./../../Assests/x4.jpg";
import zz2 from './../../Assests/zz2.jpg';
import zz12 from './../../Assests/zz4.jpg';
import zz25 from './../../Assests/zz26.jpg';


const Offer = () => {
  return (
    <div className={classes.steps}>

        <h1 className={classes.ih31}>What We Offer</h1>

<br/>

    


<div className={classes.steps1}> 

<div className={classes.dbn}> <img src={zz2} className={classes.imgs} /> <h5 className={classes.ih3}>Interior Design</h5>  <button className={classes.b1}><Link to='/Packages'  className={classes.be}>Get Quote Now &nbsp; ›</Link></button> </div>

<div className={classes.dbn}> <img src={zz12} className={classes.imgs} /> <h5 className={classes.ih3}>Turnkey Project</h5>  <button className={classes.b1}><Link to='/Packages'  className={classes.be}>Get Quote Now &nbsp; ›</Link></button> </div>

<div className={classes.dbn}> <img src={zz25} className={classes.imgs} /> <h5 className={classes.ih3}>Modular Furniture</h5><button className={classes.b1}><Link to='/Packages'  className={classes.be}>Get Quote Now &nbsp; ›</Link></button> </div>


</div>

<br/> <br/>
<br/>




    </div>
  )
}

export default Offer


