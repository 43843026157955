
import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from "./../../Assests/logo.png";;

export default function Wpp() {
    return (
        <div className="App">
            <FloatingWhatsApp
                phoneNumber="9762439208 "
                accountName="Vastushilp Interior Designers"
                avatar={logo}
                chatMessage="Please feel free to ask About any Querry"
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </div>
    );
}