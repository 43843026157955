import React from 'react'
import { useRef } from 'react';
import {Link} from 'react-router-dom'

import "./Footer.css"

import logo2 from "./../../Assests/logo.png";
import Heart from "./../../Assests/eziigj.gif"
import Fade from 'react-reveal/Fade';
import Wpp from "./Wpp"
import Fb from "./../../Assests/facebook.png"
import Insta from "./../../Assests/instagram.png"
import Yt from "./../../Assests/youtube.png"
import In from "./../../Assests/lkdin.png"


import A1 from "./../../Assests/x5.jpg";


import A2 from "./../../Assests/z25.webp";
import A3 from "./../../Assests/z82.webp";
import A4 from "./../../Assests/z34.webp";

/*import emailjs from '@emailjs/browser';*/

const Footer = () => {


    const form = useRef();

  /*  const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_5nlyw6e', 'template_bd7vtha', form.current, 'bsz7LIwTvX6ZYGIM3')
        .then((result) => {
  
          alert("Message Sent Successfully !!!")
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };*/

  return (
    <div> 

        <Wpp/>
    <div className='footer'>

<div className='footer1'>

     <div className='column cd'>
    

<img src={logo2} className="fi"/>
<br></br>
{/* <h4 className='fh4'>AMBIONICS</h4> *    <p className='fp'>Vastushilp Design is a leading Brand providing Best Interior Designing Solutions </p> */}
<div  className='di'>

<a href ="https://www.facebook.com/people/Vastushilp-Design-by-Mrunnali-Gurav/100089163747457/" ><img src={Fb} alt="Vastushilp Design" className='jjjx'/></a>
<a href ="https://www.instagram.com/interiorsby_mrunnaligurav/" ><img src={Insta} alt="Vastushilp Design" className='jjjx'/></a>
<a href ="https://www.youtube.com/@InteriorsbyMrunnaliGurav" ><img src={Yt} alt="Vastushilp Design" className='jjjx'/></a>
</div>
  </div>


<div className='column cd'>
    
    <div>
<p className='fh5'>EMAIL :</p>
<a className='fp2' href="vastushilp@outlook.com">vastushilp@outlook.com</a>  
    </div>

    <div>
<p className='fh5'>PHONE :</p>
<a className='fp2 jk' href="tel:9762439208">+919762439208
 </a>  
    </div>

  




</div>



<br />
<div className='column bd'> 

<p className='fh5'>ENQUIRE HERE</p>

<form action='https://formsubmit.co/vastushilp@outlook.com' method='POST' enctype="multipart/form-data">
{/*<form  ref={form} onSubmit={sendEmail} > */}


<input placeholder='ENTER YOUR NAME' className='I1' name='name' id='name' type="text"></input><br />
<input placeholder='ENTER MOBILE NO' maxLength={10} className='I1' name='phn' id='phn' type="text"></input><br />
<input placeholder='ENTER YOUR EMAIL ID' className='I1' name='mail' id='mail' type="email"></input><br />
<textarea placeholder='DESCRIPTION' rows="4" className='t1' name='des' id='des'></textarea><br />
<input type="submit" value="SUBMIT" />
</form>


</div>


<br />





</div>

<div className='footer2'>
<center className='klk'>Copyright © 2024 VASTUSHILP INTERIOR DESIGNS | Made with <img src={Heart} className="hrt"></img> by CORTICA WEB SOLUTIONS PVT LTD</center>
</div>

    </div>
    </div>
  )
}

export default Footer