import React from "react";
import Slider from "react-slick";
import Rcard from "./Rcard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classes from "./Rsli.module.css";

import t1 from "./../../Assests/golde.png";
import t2 from "./../../Assests/siresj.png";
import t3 from "./../../Assests/dipika.png";
import t4 from "./../../Assests/p.png";
import t5 from "./../../Assests/nikhil.png";
import t6 from "./../../Assests/aparna.png";
import t7 from "./../../Assests/t8.jpg";

const Rsli = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 769,
              settings: {
                speed: 1000,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false  
              }
            }
        ]
    };

    return (
<div> 

<div className={classes.rsl}>
       <h1 className={classes.ih31}>
        Our Testimonial
       </h1>
<div className={classes.rsl2}>      
                <Slider {...settings}>

<div>
<Rcard imgg={t1} name='Mr Deepak Golde' review='Very very osum service.They will answer all of your queries and will guide you whenever needed.100% hassle free work. 100% satisfaction guaranteed. We are very very glad that we opted Vastushilp.' ></Rcard>
</div>
<div>
<Rcard imgg={t2} name='Mr Suresh Lad' review='The team has given nice suggestions regarding each and every aspects of furniture required as per available space and work site, utilised space very efficiently.Quality work done, really appreciable.Particularly  Ladies interior team given more than 100% efforts to make our kitchen better and very usable as per requirements.Storngly recommended for others for nice furniture wk' ></Rcard>
</div>
<div>
<Rcard imgg={t3} name='Mrs Dipika Katkar' review='A big shout out for Mrunali Mam.
They listened to all our queries and made my place just perfect. Easy to work with, plus great taste with ability to stay on budget. They helped me find the perfect look for my new home. They were very attentive to every small details ...
They understand your vision and make it a reality with amazing end results.' ></Rcard>
</div>
<div>
<Rcard imgg={t4} name='Mr Prashant Salunkhe' review='We just bought a flat but Vastushilp team made it Home. It is not only about interior work and all but also it is fulfillment of our dreams we seen.
Thanks a  lot to whole team of Vastushilp who made my dreams comes true.' ></Rcard>
</div>
<div>
<Rcard imgg={t5} name='Mr Nikhil Jalgaonkar' review='I am satisfied with the outcome of all the hard work done by entire team at Vastushilp for the interior work of our 2 bhk apartment. Mrunali did an awesome job with the designs by understanding our requirements really well. Her insights and suggestions at every stage of the project were helpful in making right choices for the kind of outcome we desired. Execution of the project went well and within budget. Both Abhijeet and Mrunali were always responsive and available to resolve our queries. Quality of work, professionalism and value for money is what you would expect from Vastushilp.' ></Rcard>
</div>
<div>
<Rcard imgg={t6} name='Mrs Aprana Rakshe' review='Good products at competitive prices with really excellent service. The whole process from ordering, acknowledgement was extremely efficient and courteously followed through.' ></Rcard>
</div>



                </Slider>
                </div>
       </div>     
   </div>    
    );
}

export default Rsli;
