import React from 'react'
import classes from './Contact.module.css';
import pm1 from './../../Assests/placeholder.png';
import pm2 from './../../Assests/iiu2.png';
import pm3 from './../../Assests/iiu.png';
import { useRef } from 'react';
/*
import emailjs from '@emailjs/browser';*/

const Contact = () => {

  /*  const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_5nlyw6e', 'template_bd7vtha', form.current, 'bsz7LIwTvX6ZYGIM3')
        .then((result) => {
  
          alert("Message Sent Successfully !!!")
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };*/

  return (
    <div className={classes.yx}>
        <div className={classes.yx1}> 
            <div id="ip" className={classes.ip}>
                    <h1 className={classes.cs}>Contact Us</h1>
                <div className={classes.vm}>

       
                  <div className={classes.kk}>
                    <img src={pm2} className={classes.qo1}></img>
                     <br/>
                     <br/>
                    <p className={classes.ph2}>
                      
                      <a href="tel:9762439208" className={classes.kzk}>+91 9762439208</a>
    </p>
                     
                    </div>
                 

                     <br/>
                
                      <div className={classes.kk}>
                    <img src={pm3} className={classes.qo1}></img>
                    <br/>
                    <br/>
                    <p className={classes.ph2}><a href="mailto:vastushilp@outlook.com" className={classes.kzk}>vastushilp@outlook.com     </a></p>
                </div>
                
                </div>
            </div>
       </div>
       <div className={classes.yx2}>
           <div>
               <h2 className={classes.ks}>Leave Us a Message</h2>
           </div>
          
     {/*           
<form  ref={form} onSubmit={sendEmail}>

*/}
       
<form className='qo5'>
<input placeholder='ENTER YOUR NAME' className={classes.I1} name='name' id='name' type="text"></input><br />
<input placeholder='ENTER MOBILE NO' maxLength={10} className={classes.I1}  name='phn' id='phn' type="text"></input><br />
<input placeholder='ENTER YOUR EMAIL ID' className={classes.I1}  name='mail' id='mail' type="email"></input><br />
<textarea placeholder='DESCRIPTION' rows="4" className={classes.t1}  name='des' id='des'></textarea><br />
<input type="submit" value="SUBMIT"  className={classes.slidebutton3}/>
</form>
                 
       </div><br/><br/>
    </div>
  )
};

export default Contact;
