import React from "react";
import Slider from "react";
import { useEffect } from "react";

import Carousel from "../Component/Home/Carousel";
import Intro from "../Component/Home/Intro";

import Vdo from "../Component/Home/Vdo"
import Offer from "../Component/Home/Offer"
import Wcy from "../Component/Home/Wcy"
import VP from "../Component/Home/Vp"
import Rsli from "../Component/Home/Rsli"
import Steps from "../Component/About/Steps"
import Abt from "../Component/About/Abt";
import Counter from "../Component/About/Counter";

/* <Carousel /> */
const Home = () => {

  useEffect(()=>{
    window.scrollTo(0,0)


document.title = ' Vastushilp Interior Designs : Best Interior Designers in Pune ';

// Set meta description dynamically
const homeMetaDescription = document.createElement('meta');
homeMetaDescription.name = 'description';
homeMetaDescription.content = 'Transform your home or office with Vastushilp Interior Designs, the best interior designers in Pune. Experience expertly crafted interiors that blend style and functionality, inspired by timeless principles of Vastu Shastra.';
document.head.appendChild(homeMetaDescription);

// Set meta keywords dynamically
const homeMetaKeywords = document.createElement('meta');
homeMetaKeywords.name = 'keywords';
homeMetaKeywords.content = 'Best Interior Designers in Pune, Vastushilp Interior Designs, Interior Design Pune, Home Decor Pune, Office Interior Design Pune, Vastu Shastra, Residential Interior Design, Commercial Interior Design, Space Planning, Furniture Design, Pune Interior Decorators';
document.head.appendChild(homeMetaKeywords);

// Set canonical link dynamically
const homeCanonicalLink = document.createElement('link');
homeCanonicalLink.rel = 'canonical';
homeCanonicalLink.href = 'https://vastushilpdesigns.com/';
document.head.appendChild(homeCanonicalLink);

// Set Open Graph title dynamically
const homeOgTitle = document.createElement('meta');
homeOgTitle.property = 'og:title';
homeOgTitle.content = 'Vastushilp Interior Designs : Best Interior Designers in Pune';
document.head.appendChild(homeOgTitle);

// Set Open Graph description dynamically
const homeOgDescription = document.createElement('meta');
homeOgDescription.property = 'og:description';
homeOgDescription.content = 'Transform your home or office with Vastushilp Interior Designs, the best interior designers in Pune. Experience expertly crafted interiors that blend style and functionality, inspired by timeless principles of Vastu Shastra.';
document.head.appendChild(homeOgDescription);

// Set Open Graph keywords dynamically
const homeOgKeywords = document.createElement('meta');
homeOgKeywords.property = 'og:keywords';
homeOgKeywords.content = 'Best Interior Designers in Pune, Vastushilp Interior Designs, Interior Design Pune, Home Decor Pune, Office Interior Design Pune, Vastu Shastra, Residential Interior Design, Commercial Interior Design, Space Planning, Furniture Design, Pune Interior Decorators';
document.head.appendChild(homeOgKeywords);

// Set Open Graph URL dynamically
const homeOgUrl = document.createElement('meta');
homeOgUrl.property = 'og:url';
homeOgUrl.content = 'https://vastushilpdesigns.com/';
document.head.appendChild(homeOgUrl);

// Set Open Graph site name dynamically
const homeOgSiteName = document.createElement('meta');
homeOgSiteName.property = 'og:site_name';
homeOgSiteName.content = 'Vastushilp Interior Designs';
document.head.appendChild(homeOgSiteName);

// Set Open Graph image dynamically
const homeOgImage = document.createElement('meta');
homeOgImage.property = 'og:image';
homeOgImage.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(homeOgImage);

// Set Open Graph secure image URL dynamically
const homeOgImageSecure = document.createElement('meta');
homeOgImageSecure.property = 'og:image:secure_url';
homeOgImageSecure.content = 'https://vastushilpdesigns.com/logo.png';
document.head.appendChild(homeOgImageSecure);

// Set Open Graph image width dynamically
const homeOgImageWidth = document.createElement('meta');
homeOgImageWidth.property = 'og:image:width';
homeOgImageWidth.content = '300';
document.head.appendChild(homeOgImageWidth);

// Set Open Graph image height dynamically
const homeOgImageHeight = document.createElement('meta');
homeOgImageHeight.property = 'og:image:height';
homeOgImageHeight.content = '200';
document.head.appendChild(homeOgImageHeight);

// Cleanup on component unmount
return () => {
  document.title = 'Vastushilp Interior Designs : Best Interior Designers in Pune'; // Reset the title if needed
  document.head.removeChild(homeMetaDescription);
  document.head.removeChild(homeMetaKeywords);
  document.head.removeChild(homeCanonicalLink);
  document.head.removeChild(homeOgTitle);
  document.head.removeChild(homeOgDescription);
  document.head.removeChild(homeOgKeywords);
  document.head.removeChild(homeOgUrl);
  document.head.removeChild(homeOgSiteName);
  document.head.removeChild(homeOgImage);
  document.head.removeChild(homeOgImageSecure);
  document.head.removeChild(homeOgImageWidth);
  document.head.removeChild(homeOgImageHeight);
};


},[])

return (
<div>

<Vdo />
<Wcy/>
{/*<Intro />*/}
<Offer />
<Rsli/>
<Steps />
<Abt/>
<Counter />


</div>


)

}


export default Home;